import React, { useState, useEffect } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
// const MaterialCommunityIcons = React.lazy(() =>
//   import("@expo/vector-icons/MaterialCommunityIcons")
// );
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import axios from "../utils/axios";
import Auth from "./auth";
const Conversations = React.lazy(() => import("./conversations"));
const Home = React.lazy(() => import("./home"));
const Matches = React.lazy(() => import("./matches"));
const Settings = React.lazy(() => import("./settings"));
const Notifications = React.lazy(() =>
  import("../components/notificaitons/Notifications")
);
// import Notifications from "../components/notificaitons/Notifications";
// import Conversations from "./conversations";
// import Home from "./home";
// import Matches from "./matches";
import AuthContext from "../contexts/AuthContext";
// import Settings from "./settings";
import SnackBarContext from "../contexts/SnackbarContext";
import { ActivityIndicator, Snackbar } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { useQuery, useQueryClient, focusManager } from "@tanstack/react-query";
import { AppState, Platform } from "react-native";
import { queryCountNotifications } from "../data/notifications";
import { queryAccount } from "../data/account";
import { ERRORS, SUCCESS } from "../utils/enum";
import LoadingIndicator from "../components/polyvalent/loading";

const Tab = createBottomTabNavigator();

const Main = () => {
  const [snack, setSnack] = useState({ displayed: false });
  const queryClient = useQueryClient();
  const disconnect = () => {};
  const connect = (account) => {};

  function onAppStateChange(status) {
    if (Platform.OS !== "web") {
      focusManager.setFocused(status === "active");
    }
  }

  const { data: auth, isPending, isFetching } = queryAccount();
  const { data: notifications } = queryCountNotifications({
    enabled: auth?.loggedIn && !isPending,
  });

  useEffect(() => {
    const subscription = AppState.addEventListener("change", onAppStateChange);

    return () => subscription.remove();
  }, []);
  useEffect(() => {
    const errorStyle = { backgroundColor: "darkred" };
    const successStyle = { backgroundColor: "lightgreen" };
    axios.interceptors.response.use(
      (response) => {
        // Return the response if it's successful
        // console.log("response code", response.status);
        console.log("response data", response.data);
        if (response.data?.successMsg) {
          setSnack({
            displayed: true,
            text: SUCCESS[response.data?.successMsg],
            style: successStyle,
          });
        }
        return response;
      },
      (error) => {
        // Handle response error
        if (error.response) {
          console.log(
            "error status",
            error.response.status,
            error.response.status === 401
          );
          // The request was made and the server responded with a status code
          if (error.response.status === 401) {
            // Handle 401 Unauthorized error
            // For example, redirect to login page
            // queryClient.resetQueries({
            //   queryKey: ["account"],
            // });
            if (auth?.loggedIn) {
              setSnack({
                displayed: true,
                text: "Session expired, please login again.",
                style: errorStyle,
              });
            }
          } else if (error.response.status === 403) {
            // Handle 401 Unauthorized error
            // For example, redirect to login page
            setSnack({
              displayed: true,
              text: "You are not authorized to perform this action",
              style: errorStyle,
            });
          } else if (error.response.status === 429) {
            // Handle 401 Unauthorized error
            // For example, redirect to login page
            setSnack({
              displayed: true,
              text: "Too many requests, please wait a few seconds.",
              style: errorStyle,
            });
          } else {
            // Handle other status codes
            console.log("error", error.response);
            setSnack({
              displayed: true,
              text:
                error.response.data && error.response.data.errorMessage
                  ? ERRORS[error.response.data.errorMessage]
                  : "An error occured, please try again.",
              style: errorStyle,
            });
          }
        } else {
          // The request was made but no response was received
          console.log("Error:", error.message, error, error.status);
          setSnack({
            displayed: true,
            style: errorStyle,
            text: "Network error, please check your connection and try again.",
          });
        }
        return Promise.reject(error);
      }
    );
  }, [auth]);

  if (isPending || isFetching || (!auth?.loggedIn && isFetching)) {
    return <LoadingIndicator />;
  } else
    return (
      <AuthContext.Provider
        value={{ auth, disconnect, connect, notifications }}
      >
        <SnackBarContext.Provider value={{ setSnack }}>
          <SafeAreaProvider>
            {auth?.loggedIn ? (
              <Tab.Navigator
                initialRouteName={
                  auth && !auth?.description && !auth?.profilePicture
                    ? "Account"
                    : "Feed_page"
                }
                screenOptions={{
                  tabBarActiveTintColor: "red",
                  tabBarInactiveTintColor: "#ADD8E6",
                  headerShown: false,
                  tabBarShowLabel: false,
                }}
              >
                <Tab.Screen
                  name="Feed_page"
                  options={{
                    headerShown: false,
                    tabBarLabel: "Feed",
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons
                        name="home"
                        color={color}
                        size={size}
                      />
                    ),
                  }}
                >
                  {() => (
                    <React.Suspense fallback={ActivityIndicator}>
                      <Home />
                    </React.Suspense>
                  )}
                </Tab.Screen>
                <Tab.Screen
                  name="Matches_page"
                  options={{
                    tabBarLabel: "People",
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons
                        name="heart-flash"
                        color={color}
                        size={size}
                      />
                    ),
                  }}
                  message
                >
                  {() => (
                    <React.Suspense fallback={ActivityIndicator}>
                      <Matches />
                    </React.Suspense>
                  )}
                </Tab.Screen>

                <Tab.Screen
                  name="Conversations_page"
                  options={{
                    tabBarLabel: "Conversations",
                    headerShown: false,
                    tabBarBadge: auth?.unreadConversations || null,
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons
                        name="message"
                        color={color}
                        size={size}
                      />
                    ),
                    tabBarBadge: notifications?.unreadConversations || null,
                  }}
                >
                  {() => (
                    <React.Suspense fallback={ActivityIndicator}>
                      <Conversations />
                    </React.Suspense>
                  )}
                </Tab.Screen>

                <Tab.Screen
                  name="Notifications"
                  options={{
                    tabBarLabel: "Notifications",
                    tabBarBadge: notifications?.unseenNotifications || null,
                    headerShown: true,
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons
                        name="bell"
                        color={color}
                        size={size}
                      />
                    ),
                  }}
                >
                  {() => (
                    <React.Suspense fallback={ActivityIndicator}>
                      <Notifications />
                    </React.Suspense>
                  )}
                </Tab.Screen>

                <Tab.Screen
                  name="Account"
                  options={{
                    tabBarLabel: "Account",
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons
                        name="account"
                        color={color}
                        size={size}
                      />
                    ),
                  }}
                >
                  {() => (
                    <React.Suspense fallback={ActivityIndicator}>
                      <Settings />
                    </React.Suspense>
                  )}
                </Tab.Screen>
              </Tab.Navigator>
            ) : (
              <Auth />
            )}

            <Snackbar
              visible={snack.displayed}
              onDismiss={() => setSnack({ displayed: false })}
              style={snack.style}
              rippleColor="white"
              duration={4000}
              // action={{
              //   label: "Undo",
              //   onPress: () => {
              //     // Do something
              //   },
              // }}
            >
              {snack.text}
            </Snackbar>
          </SafeAreaProvider>
        </SnackBarContext.Provider>
      </AuthContext.Provider>
    );
};

// const styles = StyleSheet.create({
//   bottomNavigation: { marginBottom: 5 },
// });

export default Main;
