import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "../utils/axios";

export const queryAccountURL = "/account";

export function queryAccount() {
  const fetchAccount = async ({ pageParam = null }) => {
    try {
      const res = await axios.get(queryAccountURL);
      return { ...res.data, userId: res.data._id };
    } catch (e) {
      return { loggedIn: false };
    }
  };
  return useQuery({
    queryFn: fetchAccount,
    queryKey: ["account"],
  });
}

const mutationVerifyAccountURL = "";

export function mutationVerifyAccount({ onSuccess }) {
  return useMutation({
    mutationFn: async () => {
      const message = await axios.post(mutationVerifyAccountURL);
      return message.data;
      // if (image) setImage(null);
      // if (audio) setAudio(null);
    },
    onSuccess,
  });
}
