import React, { useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet, Platform, Alert } from "react-native";
import { TextInput, Button, Banner } from "react-native-paper";
import { Formik } from "formik";
import * as yup from "yup";
import AuthContext from "../../contexts/AuthContext";
import * as Notifications from "expo-notifications";
import axios from "../../utils/axios";

import * as SecureStore from "expo-secure-store";
import * as Linking from "expo-linking";
import LoadingIndicator from "../polyvalent/loading";
import { useQueryClient } from "@tanstack/react-query";
import { loginMutation } from "../../data/auth";
import LogoThiiink from "../polyvalent/logo";
import { ScrollView } from "react-native";
import ThiiinkBanner from "../polyvalent/Banner";
import { useNavigation, useRoute } from "@react-navigation/native";
import { TouchableOpacity } from "react-native";
// import {
//   GoogleSignin,
//   statusCodes,
// } from "@react-native-google-signin/google-signin";
const { GoogleSignin, statusCodes } =
  Platform.OS === "web"
    ? { GoogleSignin: null, statusCodes: null }
    : require("@react-native-google-signin/google-signin");
GoogleSignin?.configure({
  webClientId:
    "258031888732-l14k17sffg2l5uqh7hhm7v6dlko3e8ir.apps.googleusercontent.com",
});

const Login = () => {
  const [defaultValues, setDefaultValues] = useState(null);
  const route = useRoute();
  const url = Linking.useURL();
  const id_token = route?.params?.id_token;
  const navigation = useNavigation();
  // const { connect } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
      .max(200, "Password cannot exceed 200 characters."),
  });

  const { mutateAsync, isPending } = loginMutation({
    onSuccess: () => queryClient.invalidateQueries(["account"]),
    onError: (e) => console.log("Error login", e),
  });

  useEffect(() => {
    console.log("Loading code");
    (async () => {
      if (url?.includes("id_token=")) {
        const match = url
          .match(/id_token=([^&]*)/)
          .at(0)
          .replace("id_token=", "");
        console.log("match", match);
        const results = await mutateAsync({
          token: match,
        });
      }
    })();
  }, [url]);

  useEffect(() => {
    (async () => {
      try {
        let email = "",
          password = "";
        if (Platform.OS !== "web") {
          email = (await SecureStore.getItemAsync("email")) || "";
          password = (await SecureStore.getItemAsync("password")) || "";
        }
        setDefaultValues({ email, password });
      } catch (e) {
        console.log(e);
        setDefaultValues({ email: "", password: "" });
      }
    })();
  }, []);

  return (
    <>
      {/* <GoogleSigninButton
        size={GoogleSigninButton.Size.Wide}
        color={GoogleSigninButton.Color.Dark}
        onPress={async () => {
          try {
            await GoogleSignin.configure();
            await GoogleSignin.hasPlayServices();
            Alert.alert("has play services");
            const userInfo = await GoogleSignin.signIn();
            Alert.alert(userInfo?.email);
          } catch (error) {
            // Alert.alert(error.code);

            if (error.code === statusCodes.SIGN_IN_CANCELLED) {
              // user cancelled the login flow
              Alert.alert("canceled");
            } else if (error.code === statusCodes.IN_PROGRESS) {
              // operation (e.g. sign in) is in progress already
              Alert.alert("in progress");
            } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
              // play services not available or outdated
              Alert.alert("play services");
            } else {
              // some other error happened
              Alert.alert("other error", JSON.stringify(error));
            }
          }
        }}
        // disabled={this.state.isSigninInProgress}
      /> */}

      {defaultValues ? (
        <Formik
          initialValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={mutateAsync}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            handleBlur,
            touched,
            isSubmitting,
          }) => (
            <ScrollView
              style={{
                maxWidth: 1200,
                width: "100%",
                alignSelf: "center",
                flex: 1,
              }}
            >
              <ThiiinkBanner
                visible
                text="A fully-featured social media platform devoted to Mental Health, where users and certified advisors can openly discuss and share their experiences."
              />
              <View style={{ marginVertical: 10 }}>
                <Text
                  style={{ color: "white", fontSize: 18, textAlign: "center" }}
                >
                  By logging in, you agree that you have fully read, understood,
                  and accepted our{" "}
                  <TouchableOpacity
                    style={{ color: "lightblue" }}
                    onPress={() => navigation.navigate("Legal")}
                  >
                    <Text style={{ textDecorationLine: "underline" }}>
                      Privacy Policy
                    </Text>
                  </TouchableOpacity>{" "}
                  and{" "}
                  <TouchableOpacity
                    style={{ color: "lightblue" }}
                    onPress={() => navigation.navigate("Legal")}
                  >
                    <Text style={{ textDecorationLine: "underline" }}>
                      Terms of Use
                    </Text>
                  </TouchableOpacity>
                  .
                </Text>
              </View>
              <Button
                onPress={async () => {
                  const url =
                    "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?nonce=nonce&response_mode=query&response_type=id_token&redirect_uri=https%3A%2F%2Fwww.thiiink.site%2Flogin&scope=email%20profile&client_id=258031888732-l14k17sffg2l5uqh7hhm7v6dlko3e8ir.apps.googleusercontent.com&service=lso&o2v=2&flowName=GeneralOAuthFlow";
                  if (isPending) return;
                  if (Platform.OS === "web") Linking.openURL(url, "_blank");
                  else {
                    try {
                      await GoogleSignin.hasPlayServices();
                      const userInfo = await GoogleSignin.signIn();
                      // Alert.alert(JSON.stringify(userInfo.idToken));
                      await mutateAsync({
                        token: userInfo.idToken,
                      });
                    } catch (error) {
                      if (error.code === statusCodes.SIGN_IN_CANCELLED) {
                        // user cancelled the login flow
                        // Alert.alert("canceled");
                      } else if (error.code === statusCodes.IN_PROGRESS) {
                        // operation (e.g. sign in) is in progress already
                        // Alert.alert("in progress");
                      } else if (
                        error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE
                      ) {
                        // play services not available or outdated
                        Alert.alert(
                          "Can't connect using Google, Play Services are not available."
                        );
                      } else {
                        // some other error happened
                        Alert.alert(
                          "Can't connect using Google, Unknown error.",
                          JSON.stringify(error)
                        );
                      }
                    }
                  }
                }}
                icon="google"
                style={{ padding: 5, fontSize: 50, color: "white" }}
                buttonColor="blue"
                mode="contained"
                loading={isPending}
                // disabled={isPending}
                textColor="white"
              >
                Login with Google
              </Button>

              <LogoThiiink text={"Welcome to Thiiink."} />

              <TextInput
                style={styles.inputs}
                placeholder="Email"
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                value={values.email}
              />
              {touched.email && errors.email && (
                <Text style={styles.error}>{errors.email}</Text>
              )}
              <TextInput
                placeholder="Password"
                style={styles.inputs}
                secureTextEntry
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                value={values.password}
              />
              {touched.password && errors.password && (
                <Text style={styles.error}>{errors.password}</Text>
              )}

              <Button
                style={styles.inputs}
                loading={isPending}
                onPress={handleSubmit}
                mode="contained"
              >
                Login
              </Button>
            </ScrollView>
          )}
        </Formik>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  inputs: { marginBottom: 8 },
  error: {
    color: "red",
    marginTop: 5,
    marginBottom: 5,
  },
});

export default Login;
