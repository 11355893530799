import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  // baseURL: "http://10.0.2.2:3000",
  baseURL: __DEV__
    ? "http://192.168.137.1:3000"
    : "https://7885ue44y7.execute-api.us-east-1.amazonaws.com/dev/",
  // baseURL: "http://localhost:3000",
  timeout: 40000,
});

export default instance;
