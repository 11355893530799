import React from "react";
import { View, ActivityIndicator, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { Image } from "expo-image";

const LogoThiiink = (props) => {
  return (
    <View>
      <Image
        source={require("../../assets/Thiiink_zoomed_white.png")}
        contentFit="contain"
        style={{
          width: props.size || "50%",
          maxWidth: 400,
          aspectRatio: 1,
          alignSelf: "center",
          margin: 5,
          padding: 0,
          // maxHeight: "100%",
        }}
      />
      {props.text && (
        <Text
          style={{
            marginBottom: 10,
            textAlign: "center",
            fontSize: 18,
            color: "white",
          }}
        >
          {props.text}
        </Text>
      )}
    </View>
  );
};

// const styles = StyleSheet.create({
// ginVertical: 5,
//   },
// });

export default LogoThiiink;
