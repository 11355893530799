import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "../utils/axios";

export const queryNotificationsURL = "/account/notifications";

export function queryNotifications({}) {
  const fetchNotifications = async ({ pageParam = null }) => {
    const { data } = await axios.get(
      `${queryNotificationsURL}?${pageParam ? "cursor=" + pageParam : ""}`
    );
    return data;
  };
  return useInfiniteQuery({
    queryKey: ["notifications_list"],
    queryFn: fetchNotifications,
    getNextPageParam: (lastPage) => {
      if (lastPage?.length < 15) return undefined;
      else return lastPage.at(-1)._id;
    },
  });
}

export const queryCountNotificationsURL = "/account/notifications/count";

export function queryCountNotifications({ enabled }) {
  const fetchNotifications = async ({ pageParam = null }) => {
    const res = await axios.get(queryCountNotificationsURL);
    return { ...res.data };
  };
  return useQuery({
    queryFn: fetchNotifications,
    queryKey: ["notifications"],
    // enabled: !isLoading && auth?.loggedIn,
    enabled,
    staleTime: 1,
    refetchInterval: 10 * 60 * 1000,
  });
}
