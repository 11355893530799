import {
  useInfiniteQuery,
  useQueryClient,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import axios from "../utils/axios";
import { Platform } from "react-native";
import * as Notifications from "expo-notifications";
import * as SecureStore from "expo-secure-store";
import Constants from "expo-constants";
export const loginMutationURL = "/auth/login";
export const googleMutationURL = "/auth/google";

export function loginMutation({ onSuccess, onError }) {
  async function registerForPushNotificationsAsync() {
    let token;
    // if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync({
        ios: {
          allowAlert: true,
          allowBadge: true,
          allowSound: true,
          allowAnnouncements: true,
        },
      });
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert(
        "You will not receive notifications from us. Please don't forget to enable them in your settings if you change your mind."
      );
      return;
    }

    const channel = await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
      enableVibrate: true,
      sound: "default",
      lockscreenVisibility: Notifications.AndroidNotificationVisibility.PUBLIC,
    });

    // console.log("Default notification channel", channel);

    // await Notifications.scheduleNotificationAsync({
    //   content: {
    //     title: "You've got mail! 📬",
    //     sound: "default", // Provide ONLY the base filename,
    //   },
    //   trigger: {
    //     seconds: 2,
    //     channelId: "default",
    //   },
    // });
    token = await Notifications.getDevicePushTokenAsync({
      projectId: Constants.expoConfig.extra.eas.projectId,
    });
    // console.log(token);
    // const experienceId = Constants?.manifest2?.id;
    // console.log("exp id", experienceId);
    return token;
  }

  const handleLogin = async (values) => {
    let token;
    if (Platform.OS !== "web") {
      token = await registerForPushNotificationsAsync();
    }
    if (values.token) {
      const res = await axios.post(googleMutationURL, {
        ...(Platform.OS === "web" ? {} : { token: token.data }),
        googleToken: values.token,
      });
      return res.data;
    } else {
      if (Platform.OS !== "web") {
        await SecureStore.setItemAsync("email", values.email);
        await SecureStore.setItemAsync("password", values.password);
      }
      const res = await axios.post(loginMutationURL, {
        email: values.email.trim(),
        password: values.password.trim(),
        ...(Platform.OS === "web" ? {} : { token: token.data }),
      });
      return res.data;
    }
  };
  return useMutation({
    mutationFn: handleLogin,
    onSuccess,
    onError,
  });
}

export const registerMutationURL = "/auth/register";

export function registerMutation({ onSuccess }) {
  const handleRegister = async (values) => {
    const res = await axios.post(registerMutationURL, { ...values }); // You can perform registration logic here
    return res.data;
  };
  return useMutation({ mutationFn: handleRegister, onSuccess });
}

export const resetCodeMutationURL = "/auth/resetCode";

export function resetCodeMutation({ onSuccess }) {
  return useMutation({
    mutationFn: async ({ email }) => {
      const message = await axios.post(resetCodeMutationURL, {
        email,
      });
      return message.data;
      // if (image) setImage(null);
      // if (audio) setAudio(null);
    },
    onSuccess,
  });
}

export const resetPasswordMutationURL = "/auth/resetPassword";

export function resetPasswordMutation({ onSuccess }) {
  return useMutation({
    mutationFn: async ({ email, password, code }) => {
      const message = await axios.post(resetPasswordMutationURL, {
        email,
        password,
        code,
      });

      return message.data;
      // if (image) setImage(null);
      // if (audio) setAudio(null);
    },
    onSuccess,
  });
}

export const confirmEmailURL = "/auth/confirm";

export function confirmEmailMutation({ onSuccess }) {
  return useMutation({
    mutationFn: async ({ code }) => {
      const message = await axios.post(confirmEmailURL, {
        id: code.split("-").at(0),
        code: code.split("-").at(1),
      });
      return message.data;
      // if (image) setImage(null);
      // if (audio) setAudio(null);
    },
    onSuccess,
  });
}
