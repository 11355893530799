import React from "react";
import { View, ActivityIndicator, StyleSheet, Text } from "react-native";
import LogoThiiink from "./logo";

const LoadingIndicator = (props) => {
  return (
    <View style={props.loadingMore ? styles.loadingMore : styles.container}>
      {props.empty ? (
        <Text
          style={{
            textAlign: "center",
            color: "white",
            fontSize: 18,
            marginTop: 20,
          }}
        >
          {props.emptyText || "Sorry, no results found."}
        </Text>
      ) : (
        <ActivityIndicator size="large" color="lightblue" />
      )}
      {!props.withoutLogo && <LogoThiiink />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    // alignItems: "center",
  },
  loadingMore: {
    marginVertical: 5,
  },
});

export default LoadingIndicator;
