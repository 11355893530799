import React from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import LoginScreen from "../components/auth/login";
// import RegisterScreen from "../components/auth/register";
const RegisterScreen = React.lazy(() => import("../components/auth/register"));
import { SafeAreaView } from "react-native-safe-area-context";
// import { LoadingIndicator } from "react-native";
import Reset from "../components/auth/reset";
import { createStackNavigator } from "@react-navigation/stack";
import Confirm from "../components/auth/confirm";
import LoadingIndicator from "../components/polyvalent/loading";
import { ActivityIndicator } from "react-native";
import WebView from "../components/polyvalent/WebView";

const Terms = React.lazy(() => import("./Legal"));
const Tab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();

function Auth() {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="HomeAuth">
        <Stack.Screen
          name="ConfirmEmail"
          options={{ title: "Confirm  |  Email" }}
          component={Confirm}
        />
        <Stack.Screen
          name="WebView_Auth"
          options={{ title: "Auth  |  Thiiink" }}
        >
          {(props) => <WebView auth {...props} />}
        </Stack.Screen>
        <Stack.Screen name="HomeAuth" options={{ headerShown: false }}>
          {() => (
            <Tab.Navigator initialRouteName="Login">
              <Tab.Screen name="Login" component={LoginScreen} />
              <Tab.Screen name="Register">
                {() => <RegisterScreen />}
              </Tab.Screen>
              <Tab.Screen name="Reset" component={Reset} />
              <Tab.Screen name="Legal">
                {() => (
                  <React.Suspense fallback={LoadingIndicator}>
                    <Terms />
                  </React.Suspense>
                )}
              </Tab.Screen>
            </Tab.Navigator>
          )}
        </Stack.Screen>
      </Stack.Navigator>
    </SafeAreaView>
  );
}

export default Auth;
