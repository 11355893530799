import { Banner } from "react-native-paper";
import LogoThiiink from "./logo";
import { useState } from "react";

const ThiiinkBanner = (props) => {
  return (
    <Banner
      style={{ marginVertical: 5 }}
      visible={props.visible}
      actions={props.actions?.map((button) => {
        return {
          label: button.label,
          onPress: () => button.onPress(),
        };
      })}
      icon={({ size }) => <LogoThiiink size={size} />}
    >
      {props.text}
    </Banner>
  );
};

export default ThiiinkBanner;
