import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Main from "./main";
import { registerRootComponent } from "expo";
import {
  NavigationContainer,
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";
import { View } from "react-native";
import ErrorBoundary from "react-native-error-boundary";
// import "react-native-gesture-handler";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import {
  Provider as PaperProvider,
  DarkTheme as DarkThemePaper,
  adaptNavigationTheme,
  MD3DarkTheme,
  MD3LightTheme,
} from "react-native-paper";
import { useEffect, useRef } from "react";
import * as Notifications from "expo-notifications";
import LoadingIndicator from "../components/polyvalent/loading";

// Option 1, initialize with API_KEY only
// init("2e790cb0436162e03a78b5559d398fbc");

const errorHandler = (error, stackTrace) => {
  /* Log the error to an error reporting service */
  // track("Uncatched Error", { error });
};
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
  handleSuccess: (e) => console.log("success notification", e),
  handleError: (e) => console.log("error notification", e),
});
const App = () => {
  const notificationListener = useRef();
  const responseListener = useRef();
  useEffect(() => {
    // track("App Initial Loading");
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        console.log("notification received", notification);
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response);
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        gcTime: 0,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
        retry: (failuresCount, error) => {
          // if (failuresCount > 6) return false;
          if (error.response) {
            if (error.response.status === 500 && failuresCount < 1) return true;
            else return false;
          } else if (failuresCount < 6) return true;
        },
      },
    },
  });
  const { DarkTheme, LightTheme } = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
  });

  const CombinedDefaultTheme = {
    ...MD3LightTheme,
    ...LightTheme,
    colors: {
      ...MD3LightTheme.colors,
      ...LightTheme.colors,
    },
  };

  const CombinedDarkTheme = {
    ...MD3DarkTheme,
    ...DarkTheme,
    colors: {
      ...MD3DarkTheme.colors,
      ...DarkTheme.colors,
    },
    backgroundColor: "#0a2351",
  };

  const config = {
    screens: {
      Feed_page: {
        initialRouteName: "Main",
        screens: {
          PostFocus: "post/:_id",
        },
      },
      Account: {
        path: "/settings",
        initialRouteName: "Edit",
        screens: {
          Edit: "/edit",
        },
      },
      Matches_page: {
        initialRouteName: "Find",
        screens: {
          Profile: "profile/:userId",
        },
      },
      HomeAuth: {
        initialRouteName: "Login",
        screens: {
          Login: "/login",
          Register: "/register",
          Legal: "/legal",
        },
      },
      ConfirmEmail: "confirm/:code",
    },
  };

  return (
    <React.Suspense fallback={LoadingIndicator}>
      <GestureHandlerRootView
        style={{
          flex: 1,
          maxHeight: "100%",
          overflow: "hidden",
          backgroundColor: "rgb(28, 27, 31)",
        }}
      >
        <View
          style={{
            maxWidth: 2200,
            marginHorizontal: "auto",
            padding: 1,
            flex: 1,
            backgroundColor: "rgb(28, 27, 31)",
            width: "100%",
            // shadowColor: "#000",
            // shadowOffset: {
            //   width: 0,
            //   height: 4,
            // },
            // shadowOpacity: 0.3,
            // shadowRadius: 4.65,

            // elevation: 8,
          }}
        >
          <ErrorBoundary onError={errorHandler}>
            <PaperProvider theme={CombinedDarkTheme}>
              <NavigationContainer
                documentTitle={{
                  formatter: (options, route) =>
                    `${
                      options?.title ?? route?.name ?? ""
                    } - Thiiink - Mental Health Social Media`,
                }}
                options={{ headerRight: () => <Text>Thiiink</Text> }}
                linking={{ config }}
                theme={CombinedDarkTheme}
              >
                <QueryClientProvider client={queryClient}>
                  <PaperProvider>
                    <Main />
                  </PaperProvider>
                </QueryClientProvider>
              </NavigationContainer>
            </PaperProvider>
          </ErrorBoundary>
        </View>
      </GestureHandlerRootView>
    </React.Suspense>
  );
};

registerRootComponent(App);
