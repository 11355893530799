import React, { Component } from "react";
import { StyleSheet, Text, View, Platform } from "react-native";

import { WebView } from "react-native-webview";
const WebViewComponent = ({ route, navigation }) => {
  return (
    <WebView
      userAgent={
        Platform.OS === "android"
          ? "Chrome/18.0.1025.133 Mobile Safari/535.19"
          : "AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75"
      }
      source={{ uri: route.params.url }}
      style={{ flex: 1 }}
    />
  );
};

export default WebViewComponent;
