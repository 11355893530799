import React, { useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";

import { Button, TextInput } from "react-native-paper";
import { Formik } from "formik";
import * as yup from "yup";
import { resetCodeMutation, resetPasswordMutation } from "../../data/auth";
import { useNavigation } from "@react-navigation/native";

const Reset = () => {
  const [defaultValues, setDefaultValues] = useState({
    email: "",
    code: "",
    password: "",
  });
  // const { connect } = useContext(AuthContext);
  const navigation = useNavigation();
  const { isPending: isPendingResetCode, mutateAsync: resetCode } =
    resetCodeMutation({
      onSuccess: () => {},
    });
  const { isPending: isPendingResetPassword, mutateAsync: resetPassword } =
    resetPasswordMutation({ onSuccess: () => {} });
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    code: yup.string().required("Reset Code is required."),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
      .max(200, "Password cannot exceed 200 characters."),
  });

  const handleReset = async ({ email, code, password }) => {
    try {
      const res = await resetPassword({ email, code, password });
      navigation.navigate("Login");
    } catch (e) {
      //   Snackbar.show({ text: "An error occured!" });
      console.log(e.response.data);
    }
  };

  return (
    <View style={{ justifyContent: "center", flex: 1, padding: 8 }}>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleReset}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          handleBlur,
          touched,
          isSubmitting,
        }) => (
          <View>
            <TextInput
              style={styles.inputs}
              placeholder="Email"
              onChangeText={handleChange("email")}
              onBlur={handleBlur("email")}
              value={values.email}
            />
            {touched.email && errors.email && (
              <Text style={styles.error}>{errors.email}</Text>
            )}
            <Button
              buttonColor="blue"
              mode="contained"
              disabled={!values.email}
              loading={isPendingResetCode}
              onPress={() => resetCode({ email: values.email })}
              style={{ marginVertical: 10 }}
            >
              Send Reset Code (Email)
            </Button>
            <TextInput
              placeholder="Reset Code (you received by email)."
              style={styles.inputs}
              onChangeText={handleChange("code")}
              onBlur={handleBlur("code")}
              value={values.code}
            />
            {touched.code && errors.code && (
              <Text style={styles.error}>{errors.code}</Text>
            )}
            <TextInput
              placeholder="New Password"
              style={styles.inputs}
              secureTextEntry
              onChangeText={handleChange("password")}
              onBlur={handleBlur("password")}
              value={values.password}
            />
            {touched.password && errors.password && (
              <Text style={styles.error}>{errors.password}</Text>
            )}

            <Button
              //   style={styles.inputs}
              loading={isPendingResetPassword}
              onPress={handleSubmit}
              mode="contained"
            >
              Reset your Password
            </Button>
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  inputs: { marginBottom: 8 },
  error: {
    color: "red",
    marginTop: 5,
    marginBottom: 5,
  },
});

export default Reset;
