import React, { useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";

import { Button, Divider, TextInput } from "react-native-paper";
import { Formik } from "formik";
import * as yup from "yup";
import {
  confirmEmailMutation,
  resetCodeMutation,
  resetPasswordMutation,
} from "../../data/auth";
import { useRoute, useNavigation } from "@react-navigation/native";
import LogoThiiink from "../polyvalent/logo";

const Confirm = ({}) => {
  const { mutateAsync, isPending } = confirmEmailMutation({
    onSuccess: () => {
      navigation.navigate("HomeAuth");
    },
  });
  const route = useRoute();
  const navigation = useNavigation();
  const params = route.params;
  return (
    <View
      style={{
        justifyContent: "center",
        flex: 1,
        padding: 8,
      }}
    >
      <LogoThiiink text="Thank you for registering. Please check your email by clicking on the button below." />
      <View
        style={{
          marginHorizontal: "auto",
          maxWidth: 800,
          width: "100%",
        }}
      >
        <Button
          //   style={styles.inputs}
          loading={isPending}
          onPress={() => mutateAsync({ code: params?.code })}
          mode="contained"
        >
          Confirm your email
        </Button>
        <Divider style={{ marginVertical: 10 }} />
        <Button
          //   style={styles.inputs}
          // loading={isPending}
          onPress={() => navigation.navigate("HomeAuth")}
          mode="contained"
          buttonColor="darkred"
          textColor="white"
        >
          Go back to Login
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  inputs: { marginBottom: 8 },
  error: {
    color: "red",
    marginTop: 5,
    marginBottom: 5,
  },
});

export default Confirm;
